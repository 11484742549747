<template>
  <div
    style="position:relative;background: rgba(1, 19, 67, 0.5);border-radius: 0.74rem;height: 1.48rem;"
    class="clearfix">
    <div
      style="width: 1.08rem;height: 1.08rem;margin:0.2rem 0.15rem;background: #f2ad4b;float: left;background:rgba(1, 19, 67, 1);border-radius: 50%;overflow: hidden;">
      <p style="width: 0.32rem;margin: 0.28rem 0.38rem;">
        <span style="color: #B1D9FF;font-size: 0.14rem;font-weight: bold;">辖区基础信息</span>
      </p>
    </div>
    <div style="padding-left: 1.38rem">
      <div class="box" style="padding-top: 0.1rem;">
        <div class="innerBox" type="flex">
          <div v-for="(item, index) in statistics" :key="index" style="text-align:center;"
               :class="(index + 1) % 4 === 0 ? 'lastOne':''">
            <p style="font-size:0.14rem;color: #FFFFFF;font-weight: 400;">{{ item.label }}</p>
            <p style="color:#00F9FF;font-size:0.18rem;font-weight: bold;margin-top:0.06rem">{{ item.count }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {

  data () {
    return {
      statistics: [
        { label: '学校（所） ', count: '0', code: 'schoolNum' },
        { label: '班级（个） ', count: '0', code: 'classNum' },
        { label: '摄像头（个）', count: '0', code: 'cameraNum' },
        { label: '报警点（个）', count: '0', code: 'alarmDeviceNum' },
        { label: '教职工（人）', count: '0', code: 'teacherNum' },
        { label: '学生（人）', count: '0', code: 'studentNum' },
        { label: '寄宿生（人）', count: '0', code: 'boarderNum' },
        { label: '家长（人）', count: '0', code: 'parentNum' }
      ]
    }
  },
  methods: {
    init (data) {
      this.statistics.forEach(item => {
        if (typeof data[item.code] !== 'undefined') {
          item.count = data[item.code]
        }
      })
    }
  }
}
</script>
<style scoped>
.innerBox > div {
  display: inline-block;
  width: 24%;
  box-sizing: border-box;
  margin-top: 0.12rem;
  border-right: 2px solid #203668;
}

.innerBox > .lastOne {
  border-width: 0;
}
</style>
